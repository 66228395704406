.popup-stream-content {
    display: flex;
    width: 100%;
    height: 300px;
    margin-top: 10px;
}

.popup-stream-content > .streamLobbyItemVideo {
    height: 100%;
    margin: 0;
}

.pros-controls {
    width: 100%;
    display: flex;
    margin-top: 32px;
    flex-direction: column;
}

.pros-controls > button:last-of-type {
    margin-top: 10px;
}

@media (max-width: 590px) {
    .popup-stream-content {
        height: 200px;
    }
}
