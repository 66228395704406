.popup-stream-content {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  display: flex;
}

.popup-stream-content > .streamLobbyItemVideo {
  height: 100%;
  margin: 0;
}

.pros-controls {
  width: 100%;
  flex-direction: column;
  margin-top: 32px;
  display: flex;
}

.pros-controls > button:last-of-type {
  margin-top: 10px;
}

@media (max-width: 590px) {
  .popup-stream-content {
    height: 200px;
  }
}

/*# sourceMappingURL=index.63bef3f7.css.map */
